export const EservicesStatus = {
    order: [
        {
            status: "1",
            variant: "order_evaluation",
            description: "In valutazione"
        },
        {
            status: "2",
            variant: "order_processing",
            description: "In preparazione"
        },
        {
            status: "3",
            variant: "order_partially_shipped",
            description: "Parzialmente spedito"
        },
        {
            status: "4",
            variant: "order_concluded",
            description: "Concluso"
        },
        {
            status: "5",
            variant: "order_reversed",
            description: "Stornato"
        },
        {
            status: "6",
            variant: "sent_to_semiramis",
            description: "In elaborazione"
        },
        {
            status: "7",
            variant: "eservice_blocked",
            description: "Bloccato"
        },
        {
            status: "8",
            variant: "eservice_canceled",
            description: "Cancellato eservice"
        }
    ],
    shipping: [
        {
            status: "1",
            variant: "shipping_concluded",
            description: "Concluso"
        },
        {
            status: "2",
            variant: "shipping_reversed",
            description: "Stornato"
        },
    ],
    invoice: [
        {
            status: "1",
            variant: "invoice_not_paid",
            description: "Non pagato"
        },
        {
            status: "2",
            variant: "invoice_partially_paid",
            description: "Parzialmente pagato"
        },
        {
            status: "3",
            variant: "invoice_completely_paid",
            description: "Completamente pagato"
        },
        // {
        //     status: "4",
        //     variant: "invoice_reversed",
        //     description: "Stornato"
        // },
    ],
    unblockable_by: [
        {
            status: "1",
            variant: "TS",
            description: "TS"
        },
        {
            status: "2",
            variant: "RSM",
            description: "RSM"
        },
        {
            status: "3",
            variant: "ASM",
            description: "ASM"
        },
        {
            status: "4",
            variant: "DSM",
            description: "DSM"
        },
        {
            status: "5",
            variant: "DIR",
            description: "DIR"
        },
        {
            status: "6",
            variant: "CM",
            description: "CM"
        }
    ],
    complaints: [
        {
            status: "2",
            variant: "offer_accepted",
            description: "Aperto"
        },
        {
            status: "3",
            variant: "order_closed",
            description: "Chiuso"
        },
    ]
};
