import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import { Item } from "../../interfaces/Item";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { EservicesStatus } from "../../constants/EservicesStatus";
import { getCurrencyDecimals } from "../../utils/getCurrencyDecimals";
import { Status, StatusVariant } from "../Status";

type PreviewOutstandingItemsTableProps = {
    items: any;
};

export default function PreviewOutstandingItemsTable({ items }: PreviewOutstandingItemsTableProps) {
    const { user } = useAuthContext();
    const { t } = useTranslation();

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    // table data
    const data = useMemo(
        () => {
            return items.filter((item: any) => { return item.code !== "PLATFORM" && item.code !== "PRIORITY" && item.code !== "RBCUFFIAPAL" && item.code !== "TELEPHONE"; });
        },
        [items]
    );

    const columns: Array<Column<any>> = useMemo(
        () => [
            {
                Header: t("Numero ordine").toString(),
                accessor: "number",
                className: "text-left p-3",
            },
            {
                Header: t("Codice articolo").toString(),
                accessor: "code",
                className: "text-left p-3",
            },
            {
                Header: t("Descrizione").toString(),
                accessor: "description",
                className: "text-left p-3",
            },
            {
                Header: t("UM").toString(),
                accessor: "uom",
                className: "text-left p-3",
            },
            {
                Header: t("Quantità ordinata").toString(),
                accessor: "qty",
                className: "text-right p-3",
                Cell: (props) => {
                    return <span>{parseInt(props.row.original.qty)}</span>;
                }
            },
            {
                Header: t("Quantità residua").toString(),
                className: "text-right p-3",
                Cell: (props) => {
                    return <span>{props.row.original.qty - props.row.original.delivered_qty}</span>;
                }
            },
            {
                Header: t("Quantità riservata").toString(),
                className: "text-right p-3",
                Cell: (props) => {
                    const qty = parseInt(props.row.original.qty);
                    const reservedQty = props.row.original.reservedqty;

                    let textColor = '';
                    if (qty === reservedQty) {
                        textColor = 'text-green-600';
                    } else if (reservedQty <= 0) {
                        textColor = 'text-red-500';
                    } else {
                        textColor = 'text-yellow-500';
                    }

                    return <span className={textColor}>{props.row.original.reservedqty}</span>;
                }
            },
            {
                Header: t("Stato").toString(),
                className: "text-left p-3",
                id: "status",
                Cell: (props: any) => {
                    const rowStatus = props.row.original.status;
                    const status = EservicesStatus.order.find((status) => status.status === rowStatus);

                    const statusVariant = status?.variant as keyof typeof StatusVariant;

                    return <Status variant={Status.variant[statusVariant]}>{t(status?.description ?? "")}</Status>;
                }
            },
            {
                Header: t("Data ordine").toString(),
                accessor: "order_date",
                className: "text-left p-3",
                Cell: (props) => {
                    const order_date = new Date(props.row.original.order_date);
                    const date = dateFormat.format(order_date);

                    return <span>{date}</span>;
                }
            },
            {
                Header: t("Data spedizione").toString(),
                accessor: "shipping_date",
                className: "text-left p-3",
                Cell: (props) => {
                    const shipping_date = new Date(props.row.original.shipping_date);
                    const preferred_date = new Date(props.row.original.preferred_date);

                    return shipping_date.getTime() !== preferred_date.getTime() ? <span>{dateFormat.format(shipping_date)}</span> : null;
                }
            },
            {
                Header: t("Valore residuo").toString(),
                className: "text-right p-3",
                Cell: (props) => {
                    const numberFormat = new Intl.NumberFormat(props.row.original.language_id, { style: "currency", currency: props.row.original.currency, minimumFractionDigits: getCurrencyDecimals(props.row.original.currency), maximumFractionDigits: getCurrencyDecimals(props.row.original.currency) });

                    return <span>{numberFormat.format((props.row.original.qty - props.row.original.delivered_qty) * props.row.original.net_price)}</span>;
                }
            },
        ],
        [t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable({ columns, data, initialState: { pageSize: 100 } }, useSortBy, usePagination);

    return (
        <div className='border'>
            <table {...getTableProps()} className="w-full">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className='odd:bg-tertiary'>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps({ className: (cell.column as any).className })}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
