import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../../../hooks/use-context/useAuthContext";
import { ItemSelect } from "../../../../commom/ItemSelect";
import { Section } from "../../../../Section";
import Select from "react-select";
import { useSelectStyles } from "../../../../../hooks/useSelectStyles";
import useShippings from "../../../../../api/shippings/useShippings";
import { useEffect, useRef, useState } from "react";

type Props = {
    loadItemsOptions: any
    handleItemSearchChange: any
    flow?: string
};

export const ComplaintProductDetails = ({ loadItemsOptions, handleItemSearchChange, flow = "product" }: Props) => {
    const { t } = useTranslation();
    const { roles, user } = useAuthContext();
    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    const { register, formState: { errors }, watch, clearErrors } = useFormContext();

    // ref
    const customsBillRef = useRef<any>(null);

    // state
    const [mappedShippings, setMappedShippings] = useState([]);

    const customer_code = watch('customer_code');
    const item_id = watch('item_id');
    const complaint_type = watch('complaint_type');

    // Reference issue 278
    const notRequiredComplaintId = ["S01", "S02", "S05", "S06", "S07", "S08", "S09", "S10", "S12", "S13"];

    const { shippings } = useShippings({
        page: 1,
        "filter[item_id]": item_id,
        "filter[customer_code]": customer_code,
        pageSize: 1000
    }, !!item_id);

    const { CustomStyles } = useSelectStyles();

    const handleCustomsBillChange = (inputValue: any, reactFormOnChange: (...event: any[]) => void) => {
        reactFormOnChange(inputValue?.slipnumber);
    };

    useEffect(() => {
        setMappedShippings(shippings?.data?.map((shipping: any) => {
            return { ...shipping, label: `${shipping.slipnumber} - ${dateFormat.format(new Date(shipping.date))}` }
        }));
    }, [shippings]);

    useEffect(() => {
        if(!item_id) {
            customsBillRef?.current?.clearValue();
        }
    },[item_id]);

    useEffect(() => {
        clearErrors("item_id");
    }, [complaint_type]);

    return (
        <fieldset>
            <Section title={"Dettagli prodotto"}>
                <legend className="sr-only">{t("Dettagli prodotto")}</legend>
                <div className='grid grid-cols-2 gap-x-6 gap-y-4'>
                    <div className={!roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? "w-full" : "flex-1"}>
                        <label>{t("Inserisci codice articolo o nome prodotto")}</label>
                        <Controller
                            name='item_id'
                            rules={{
                                validate: {
                                    required: () => !notRequiredComplaintId.find(item => item === complaint_type.slice(4, 7)) ? `${t("- questo campo è obbligatorio")}` : undefined
                                }
                            }}
                            render={({ field: { onChange }, fieldState: { error } }) => (
                                <ItemSelect
                                    name="item_id"
                                    loadItemsOptions={loadItemsOptions}
                                    handleChange={(e: any) => handleItemSearchChange(e, onChange)}
                                    error={error}
                                />
                            )}
                        />
                    </div>

                    {flow === "product" && <div>
                        <label htmlFor="lotto">{t("Numero lotto")}</label>
                        <input
                            {...register("lotto", { required: `${t("- questo campo è obbligatorio")}` })}
                            id="lotto"
                            type="text"
                            placeholder={t("inserisci il lotto")}
                        />
                        {errors?.lotto && <p className="text-sm text-rose-500 mt-1">{errors?.lotto.message}</p>}
                    </div>}

                    <div>
                        <label htmlFor="qty">{t("Quantità coinvolta che presenta il problema")}</label>
                        <input
                            {...register("qty")}
                            id="qty"
                            type="text"
                            placeholder={t("inserisci la quantità, es. 3 su 10")}
                        />
                        {errors?.qty && <p className="text-sm text-rose-500 mt-1">{errors?.qty.message}</p>}
                    </div>
                    {/* BOLLA */}
                    <div>
                        <label htmlFor="customs_bill">{t("Numero Bolla")}</label>
                        <Controller
                            name='customs_bill'
                            rules={{ required: `${t("- questo campo è obbligatorio")}` }}
                            render={({ field: { onChange }, fieldState: { error } }) => (
                                <>
                                    <Select
                                        id="customs_bill"
                                        ref={customsBillRef}
                                        options={mappedShippings}
                                        onChange={(e: any) => handleCustomsBillChange(e, onChange)}
                                        styles={CustomStyles}
                                        placeholder={t("inserisci numero della bolla")}
                                        loadingMessage={() => t("Caricamento in corso...")}
                                        noOptionsMessage={() => t("Nessun risultato")}
                                        isClearable
                                        escapeClearsValue
                                        isDisabled={!item_id}
                                    />
                                    {errors?.customs_bill && <p className="text-sm text-rose-500 mt-1">{errors?.customs_bill.message}</p>}
                                </>
                            )}
                        />
                    </div>
                </div>
            </Section>
        </fieldset>
    );
};
