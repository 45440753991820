import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Title } from "../components/Title";
import { Card } from "../components/Card";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { checkSessionNow } from "../utils/checkSession";


const Dashboard = () => {
    const { t } = useTranslation();
    const { can } = useAuthContext();
    const { roles } = useAuthContext();

    useEffect(() => {
        checkSessionNow();
    }, []);

    return (
        <div className="py-10 px-8 2xl:px-28">
            <Title>{t("Dashboard")}</Title>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                <Card highlight={true} icon={Card.icon.cart} title={roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? t("Nuovo ordine") : t("Nuovo ordine o offerta")} url='/carts'>{roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? t("Crea un ordine tramite l'inserimento veloce partendo da codice articolo o nome prodotto") : t("Crea un ordine o un'offerta tramite l'inserimento veloce partendo da codice articolo o nome prodotto")}</Card>
                <Card highlight={true} icon={Card.icon.boxCheck} title={t("I miei carrelli")} url='/lists'>{roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? t("Trasforma i tuoi carrelli salvati in ordini") : t("Trasforma i tuoi carrelli salvati in ordini o offerte")}</Card>
                <Card highlight={true} icon={Card.icon.hands} title={t("Le mie offerte")} url='/offers'>{roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? t("Consulta le tue offerte per trasformarle in ordine") : t("Consulta o modifica le tue offerte per trasformarle in ordine")}</Card>
                <Card icon={Card.icon.cartDownload} title={t("I miei ordini")} url='/orders'>{t("Visualizza gli ordini degli ultimi 24 mesi")}</Card>
                <Card icon={Card.icon.truck} title={t("Le mie spedizioni")} url='/shippings'>{t("Monitora l'avanzamento delle spedizioni")}</Card>
                <Card icon={Card.icon.note} title={t("Le mie fatture")} url='/invoices'>{t("Visualizza le fatture degli ultimi 24 mesi")}</Card>
                <Card icon={Card.icon.info} title={t("Assistenza")} url='/assistance'>{t("Hai avuto problemi con degli ordini? Hai un reclamo o reso da fare? Troviamo insieme la soluzione")}</Card>
                {/* <Card icon={Card.icon.profile} title={t("Anagrafica")} url='/profile' /> */}
                <Card icon={Card.icon.lock} title={t("Condizioni riservate")} url='/reserved-conditions'>{t("Visualizza qui la tua tabella sconti, il tuo listino personalizzato e i tuoi metodi di pagamento dedicati.")}</Card>
                {can(["can_see_margin_calculator"]) && <Card icon={Card.icon.tag} title={t("Margin calculator")} url="/margin-calculator">{t("Controlla margini per SUB e HQ")}</Card>}
            </div>
        </div>
    );
};

export default Dashboard;
