import { useEffect, useState } from "react";
import ErrorIcon from "../ErrorIcon";
import { useTranslation } from "react-i18next";


export const EditableCellSc = ({ value: initialValue, row: { original }, column, handleUpdateItem, setIsTableValid, isTableValid, cell, roles }: any) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);
    const [cellError, setCellError] = useState<string | null>(null);
    const [isDirty, setIsDirty] = useState(false);
    const { t } = useTranslation();

    const onChange = (e: any) => {
        setValue(e.target.value);
        setIsDirty(true);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = async (ev: any) => {
        if (isDirty) {
            try {
                // we must send all the values to the update endpoint everytime
                if (column.id === "discount_1") {
                    await handleUpdateItem(original.id, original.qty, value, original.discount_2);
                } else {
                    await handleUpdateItem(original.id, original.qty, original.discount_1, value);
                }

                setCellError(null);
                setIsDirty(false);

                setIsTableValid((prevState: any) => prevState.filter((cellId: any) => {
                    return cellId !== ev.target.id;
                }));
            } catch (err: any) {
                if (err.response.status === 422) {
                    setCellError(t("Lo sconto deve essere tra 0 e 99"));
                    setIsTableValid((prevState: any) => prevState.includes(ev.target.id) ? prevState : [...prevState, ev.target.id]);
                }
            }
        }
    };

    const handleFocus = (e: any) => e.target.select();

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
        setIsTableValid([]);
    }, [initialValue]);

    const discount_type = cell.row.original.pricediscounttype;
    const disabled = discount_type === "110" ? true : false;

    if (!roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"])) {
        return (
            <div className='flex items-center gap-1'>
                <input
                    id={column.id === "discount_1" ? `discount1-${original.id}` : `discount2-${original.id}`}
                    className={`${!isTableValid?.includes(column.id === "discount_1" ? `discount1-${original.id}` : `discount2-${original.id}`) ? "w-16 p-1" : "w-16 p-1 border-rose-500 outline-none rounded"} ${disabled ? "cursor-not-allowed" : ""}`}
                    type="number"
                    min={0}
                    max={99}
                    step={"1"}
                    value={isNaN(value) ? "" : (value)}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={disabled || original.manual_net_price}
                    onFocus={handleFocus}
                />
                {isTableValid?.includes(column.id === "discount_1" ? `discount1-${original.id}` : `discount2-${original.id}`) && <ErrorIcon>{cellError}</ErrorIcon>}
            </div>
        );
    } else {
        return <span>{value}</span>;
    }
};
