import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import client from "../../api/client";
import useOffersMutations from "../../api/offers/useOffersMutations";
import { useOrderMutations } from "../../api/orders/useOrderMutations";
import { downloadResource } from "../../constants/DownloadResource";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { Button } from "../Button";
import { Loader } from "../Loader";
import { useState } from "react";
import { toast } from "react-toastify";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import OrderConfirmation from "../templates/OrderConfirmation";
import { Modal } from "../commom/Modal";

type Props = {
    offer: any,
    setOpenDuplicateOffer: any
    selectedCustomer?: any
};

const OffersActions = ({ offer, setOpenDuplicateOffer }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { roles, can } = useAuthContext();

    // hooks
    const { createOrder } = useOrderMutations();
    const { proposeOffer, rejectOffer, newOfferVersion } = useOffersMutations();
    const { client_reference, rotho_notes, delivery_restriction } = useShippingContext();

    // state
    const [OpenConfirmOrder, setOpenConfirmOrder] = useState(false);
    const [open, setOpen] = useState(false);

    // PROPOSE OFFER
    const handleOfferProposal = async () => {
        await proposeOffer.mutateAsync({ cart_id: offer?.data?.id });
        navigate(`/offers/${offer?.data?.id}/created`);
    };

    // REJECT OFFER
    const handleRejectOffer = async () => {
        await toast.promise(rejectOffer.mutateAsync({ cart_id: offer?.data?.id }), {
            pending: t("Carico..."),
            success: t("Modifica effettuata con successo"),
            error: t("La modifica non è stata effettuata")
        });
    };

    // NEW OFFER VERSION
    const handleNewOfferVersion = async () => {
        const res = await newOfferVersion.mutateAsync({ offer_id: offer?.data?.id });
        navigate(`/offers/${res.data.data.id}`, { replace: true });
    };

    const downloadOfferPdf = async (ev: any) => {
        ev.preventDefault();

        const res = await client.get(`/offers/${offer?.data?.id}/download`, {
            headers: {
                responseType: "blob"
            }
        });

        // mode download
        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }

    };

    // CREATE ORDER
    const handleCreateOrder = async () => {

        try {
            const res = await createOrder.mutateAsync({ cart_id: offer?.data?.id, client_reference, rotho_notes, delivery_restriction });
            navigate(`/order-sent/${res?.data?.data?.order_id}`);
        } catch (err: any) {
            if (err.response.status === 403) {
                toast.error(err.response.data.message);
                setOpenConfirmOrder(false);
            }
        }
    };



    // OFFER CREATED OR REQUESTED (DA INVIARE)
    if (offer?.data?.offer_expired) {
        return <>
            <div className="flex justify-end gap-2 flex-wrap mt-8">
                {
                    offer?.data?.offer_proposed_at &&
                    <Button variant={Button.variant.secondary} onClick={(ev: any) => downloadOfferPdf(ev)}>
                        {t("Scarica versione PDF")}
                    </Button>
                }
                {
                    can(["can_make_offer"]) &&
                    !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                    <Button variant={Button.variant.secondary} onClick={() => setOpenDuplicateOffer(true)}>
                        {t("Duplica offerta")}
                    </Button>
                }
            </div>
        </>;
    } else if (offer?.data?.offer_state === "requested") {
        return (
            <>
                {
                    can(["can_make_offer"]) &&
                    !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                    <div className="flex justify-end gap-2 flex-wrap mt-8">
                        <Button variant={Button.variant.secondary} onClick={() => navigate(`/offers/${offer?.data?.id}`)}>
                            {t("Modifica")}
                        </Button>
                        <Button variant={Button.variant.primary} onClick={handleOfferProposal}>
                            {t("Crea offerta")}
                        </Button>
                    </div>
                }
            </>
        );
    } else if (offer?.data?.offer_state === "proposed") {
        return (
            <>
                {(createOrder.isLoading || newOfferVersion.isLoading) && <Loader />}
                <div className="flex justify-end gap-2 flex-wrap mt-8">
                    {
                        offer?.data?.offer_proposed_at &&
                        <Button variant={Button.variant.secondary} onClick={(ev: any) => downloadOfferPdf(ev)}>
                            {t("Scarica versione PDF")}
                        </Button>
                    }
                    {
                        !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ?
                            <>
                                {
                                    can(["can_make_offer"]) &&
                                    <Button variant={Button.variant.secondary} onClick={() => setOpenDuplicateOffer(true)}>
                                        {t("Duplica offerta")}
                                    </Button>
                                }

                                {
                                    can(["can_make_offer"]) &&
                                    <Button variant={Button.variant.secondary} onClick={handleNewOfferVersion}>
                                        {t("Nuova versione")}
                                    </Button>
                                }
                            </>
                            :
                            <>
                                {
                                    open &&
                                    <Modal title={t("Rifiuta offerta")} open={open} onClose={setOpen}>
                                        <p>{t("Sei sicuro di rifiutare l'offerta?")}</p>
                                        <div className="text-right space-x-2 mt-4">
                                            <Button variant={Button.variant.text} onClick={() => setOpen(false)}>{t("Annulla")}</Button>
                                            <Button variant={Button.variant.primary} onClick={handleRejectOffer}>{t("Procedi")}</Button>
                                        </div>
                                    </Modal>
                                }
                                <Button variant={Button.variant.secondary} onClick={() => setOpen(true)}>
                                    {t("Rifiuta offerta")}
                                </Button>
                            </>
                    }
                    <OrderConfirmation open={OpenConfirmOrder} setOpen={setOpenConfirmOrder} handleConfirmClick={handleCreateOrder} clientReference={offer?.data?.client_reference} />
                    {
                        can(["can_make_order"]) &&
                        offer?.data?.customer?.type !== "DUMMY CUSTOMER" &&
                        (!offer?.data?.customer?.legallock && !offer.customer?.invoicing?.legallock) &&
                        offer?.data?.items.length > 0 &&
                        <Button variant={Button.variant.primary} onClick={() => setOpenConfirmOrder(true)} isDisabled={offer?.data?.customer?.legallock || offer?.data?.customer?.invoicing?.legallock || offer?.data?.items.find((item: any) => item?.priceclassification?.startsWith("S-H0-45"))}>
                            {t("Trasforma in ordine")}
                        </Button>
                    }
                </div>
            </>
        );
    } else if (offer?.data?.offer_state === "rejected") {
        return (
            <div className="flex justify-end gap-2 flex-wrap mt-8">
                {
                    can(["can_make_offer"]) &&
                    !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                    <Button variant={Button.variant.secondary} onClick={handleNewOfferVersion}>
                        {t("Nuova versione")}
                    </Button>
                }
            </div>
        );
    } else if (offer?.data?.offer_state === "accepted") {
        return (
            <div className="flex justify-end gap-2 flex-wrap mt-8">
                {
                    offer?.data?.offer_proposed_at &&
                    <Button variant={Button.variant.secondary} onClick={(ev: any) => downloadOfferPdf(ev)}>
                        {t("Scarica versione PDF")}
                    </Button>
                }

                {
                    can(["can_make_offer"]) &&
                    !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                    <Button variant={Button.variant.secondary} onClick={() => setOpenDuplicateOffer(true)}>
                        {t("Duplica offerta")}
                    </Button>
                }
            </div>
        );
    } else {
        return null;
    }

};

export default OffersActions;
