import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

type RequireAuthProps = {
    allowedRoles?: string[]
};

const RequireAuth: React.FC<RequireAuthProps> = ({ allowedRoles }) => {
    const { user } = useAuthContext();
    const location = useLocation();

    if (!user) {
        sessionStorage.setItem("intended_route", window.location.pathname);
    }

    if (allowedRoles) {
        return (
            user?.granted_permissions?.find((permission) => allowedRoles.includes(permission))
                ? <Outlet />
                : user
                    ? <Navigate to="/dashboard" state={{ from: location }} replace />
                    : <Navigate to="/" state={{ from: location }} replace />
        );
    } else {
        return (
            user ? <Outlet />
                : <Navigate to="/" state={{ from: location }} replace />
        );
    }

};

export default RequireAuth;
