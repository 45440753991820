import client from "../api/client";
import { Button } from "../components/Button"
import { useTranslation } from "react-i18next"
import { downloadResource } from "../constants/DownloadResource";
import { Loader } from "../components/Loader";
import { useRef, useState } from "react";
import { CustomerSelect } from "../components/commom/CustomerSelect";
import useGetCustomers from "../api/customers/useGetCustomers";


type formData = {
    "filter[complaint_number]"?: string,
    "filter[item_id]"?: string,
    "filter[customer_id]"?: string,
    "filter[min_date]"?: string,
    "filter[max_date]"?: string,
    "filter[technical_sale]"?: string,
    "filter[price_classification]"?: string,
    "filter[status]"?: string,
    "filter[complaint_classification_one]"?: string,
};


export default function GrossPriceList() {
    const { t } = useTranslation();

    // refs
    const customerSelect = useRef<any>();

    // hooks
    const { loadCustomersOptions } = useGetCustomers();

    // state
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
    const [formData, setFormData] = useState<formData>({});


    // CLIENT
    const handleClientSearchChange = (inputValue: any, action: any) => {
        setSelectedCustomer(inputValue);
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, "filter[customer_id]": inputValue?.code };
            });
        } else {
            const newForm = { ...formData };
            delete newForm["filter[customer_id]"];
            setFormData(newForm);
        }
    };

    const downloadPriceListExcel = async (ev: any) => {
        ev.preventDefault();

        // avoid multiple
        if (isDownloadLoading) {
            return;
        }

        setIsDownloadLoading(true);

        const res = await client.get(`reserved-conditions/${selectedCustomer?.code}/gross-price-list/xlsx/download`, {
            headers: {
                responseType: "blob"
            }
        });

        // mode download
        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }

        setIsDownloadLoading(false);
    };

    return <>
        {(isDownloadLoading) &&
            <Loader />}

        <div className="w-1/2">
            <CustomerSelect
                label="Seleziona cliente"
                ref={customerSelect}
                loadCustomersOptions={loadCustomersOptions}
                handleChange={handleClientSearchChange}
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
            />
        </div>

        {
            selectedCustomer &&
            <Button icon={Button.icon.download}
                variant={Button.variant.primary}
                onClick={downloadPriceListExcel} className="mt-5">
                {t("Scarica listino prezzi")}
            </Button>
        }
    </>

}
