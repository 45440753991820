import { useTranslation } from "react-i18next";
import { Modal } from "../commom/Modal";
import { Button } from "../Button";
import ClientReference from "../cart/ClientReference";
import RothoNotes from "../cart/RothoNotes";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";

type OrderConfirmationProps = {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    handleConfirmClick: () => Promise<void>,
    clientReference: string
};

const OrderConfirmation = ({ open, setOpen, handleConfirmClick, clientReference }: OrderConfirmationProps) => {
    const { t } = useTranslation();

    const { roles } = useAuthContext();

    return (
        <Modal title={t("Conferma ordine")} open={open} onClose={setOpen}>
            <div className="space-y-4">
                <ClientReference clientReference={clientReference} />

                <RothoNotes />

                <p>{t("Sei sicuro di confermare il tuo ordine?")}</p>

                <div className="text-right space-x-2 mt-4">
                    <Button variant={Button.variant.text} onClick={() => setOpen(false)}>{t("Annulla")}</Button>
                    <Button variant={Button.variant.primary} onClick={handleConfirmClick}>{t("Procedi")}</Button>
                </div>
            </div>
        </Modal>
    );
};

export default OrderConfirmation;
