import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import { DeliveryAddressTableActions } from "./DeliveryAddressTableActions";

type OrdersTableProps = {
    items: any;
    setSelectedAddress?: any;
    data?: any;
    isSelectable: boolean;
};

const RadioButton = ({ row, setSelectedAddress }: any) => {

    const handleRadioChange = () => {
        setSelectedAddress(row.original);
    };

    return (
        <span>
            <input
                type="radio"
                value={row.original}
                name='addressRadio'
                onChange={() => handleRadioChange()}
                className='cursor-pointer'
            />
        </span>
    );
};

export const DeliveryAddressTable = ({ items, setSelectedAddress, data, isSelectable }: OrdersTableProps) => {
    const { t } = useTranslation();

    const [isFlashTextOn, setIsFlashTextOn] = useState(false);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

    useEffect(() => {
        if (!isSelectable) {
            setHiddenColumns(["radio"]);
        } else {
            setHiddenColumns(["actions"]);
        }
    }, [isSelectable]);

    // table data
    const tableData = useMemo(
        () => {
            return items;
        },
        [items]
    );

    const columns: Array<Column<any>> = useMemo(
        () => [
            {
                Header: "",
                id: "radio",
                Cell: RadioButton,
            },
            {
                Header: t("Nome cliente").toString(),
                accessor: "name",
                Cell: (props) => {
                    const name = props.row.original.name;

                    return (
                        <span>{name?.toUpperCase()}</span>
                    );
                }
            },
            {
                Header: t("Via").toString(),
                accessor: "street",
                Cell: (props) => {
                    const street = props.row.original.street;

                    return (
                        <span>{street?.toUpperCase()}</span>
                    );
                }
            },
            {
                Header: t("Città").toString(),
                accessor: "city",
                Cell: (props) => {
                    const city = props.row.original.city;

                    return (
                        <span>{city?.toUpperCase()}</span>
                    );
                }
            },
            {
                Header: t("Provincia").toString(),
                accessor: "region_id",
                Cell: (props) => {
                    const region_id = props.row.original.region_id;

                    return (
                        <span>{region_id?.toUpperCase()}</span>
                    );
                }
            },
            {
                Header: t("CAP").toString(),
                accessor: "postalcode"
            },
            {
                Header: t("Paese").toString(),
                accessor: "country_id",
                Cell: (props) => {
                    const country_id = props.row.original.country_id;

                    return (
                        <span>{country_id?.toUpperCase()}</span>
                    );
                }
            },
            {
                Header: " ",
                id: "actions",
                Cell: DeliveryAddressTableActions
            }
        ],
        [t, hiddenColumns]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable({ columns, data: tableData, initialState: { pageSize: 100, hiddenColumns: hiddenColumns }, setIsFlashTextOn: setIsFlashTextOn, setSelectedAddress, externalData: data }, useSortBy, usePagination);

    return (
        <div className={`overflow-y-scroll overflow-visible min-h-min border text-xs ${isSelectable ? "max-h-[300px]" : "max-h-[600px]"}`}>
            <table {...getTableProps()} className="m-0 mr-0  w-full">
                <thead className='sticky top-0 bg-white border-t-0 border-b-0'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="text-left relative">
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="p-4 after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-full after:border-b text-left font-normal">
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} className="font-light border-t-0">
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className={"group border-b last:border-b-0 bg-white"}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps({ className: (cell.column as any).className })} className={`p-4 ${isFlashTextOn ? "group-first:animate-flash-text" : ""}`} onAnimationEnd={() => setIsFlashTextOn(false)}>
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
