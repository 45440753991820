/**
 * Mappa delle currency con decimali DIVERSO da 2
 */
export const CURRENCY_DECIMALS: any = {
    "BHD": 3,
    "CVE": 0,
    "DJF": 0,
    "GNF": 0,
    "IDR": 0,
    "IQD": 3,
    "JOD": 3,
    "JPY": 0,
    "KMF": 0,
    "KRW": 0,
    "KWD": 3,
    "LYD": 3,
    "OMR": 3,
    "PYG": 0,
    "RWF": 0,
    "TND": 3,
    "UGX": 0,
    "VND": 0,
    "VUV": 0,
    "XAF": 0,
    "XOF": 0,
    "XPF": 0,
    "CLP": 0
};

export const getCurrencyDecimals = (currency: any): number => {
    if (currency !== null && currency in CURRENCY_DECIMALS) {
        return CURRENCY_DECIMALS[currency];
    }
    return 2;
};

export const getCurrencyStep = (currency: any): string => {
    if (currency !== null && currency in CURRENCY_DECIMALS) {
        switch (CURRENCY_DECIMALS[currency]) {
            case 0:
                return "1";
            case 1:
                return "0.1";
            case 3:
                return "0.001";
        }
    }
    return "0.01";
};
