import { useCallback, useMemo } from "react";
import client from "../../../../api/client";
import { ComplaintProductDetails } from "./product-detail/ComplaintProductDetails";
import debounce from "lodash.debounce";
import { useFormContext } from "react-hook-form";
import { ComplaintSelectOrder } from "./select-order/ComplaintSelectOrder";
import { ComplaintDescription } from "./complaint-description/ComplaintDescription";
import { ComplaintAttachFiles } from "./complaint-attach-files/ComplaintAttachFiles";
import { SelectProductLine } from "./complaint-product-line/SelectProductLine";
import { useAuthContext } from "../../../../hooks/use-context/useAuthContext";

type Props = {
    selectedCustomer: any
};

export const ProductFlow = ({ selectedCustomer }: Props) => {
    const { user } = useAuthContext();
    const { setValue, watch } = useFormContext();
    const watchItemCode = watch("item_id");
    const watchComplaintOrders = watch("complaint_orders");

    // handle item search SELECT change
    const handleItemSearchChange = async (inputValue: any, reactFormOnChange: any) => {
        if (inputValue) {
            reactFormOnChange(inputValue.id);
            setValue("item_priceclassification", inputValue.priceclassification);
        } else {
            setValue("item_id", "");
            setValue("item_priceclassification", null);
        }
    };

    // GET ITEMS
    const getProducts = useCallback(async (value: string) => {
        const params = {
            "filter[search]": value,
            "filter[customer_code]": selectedCustomer?.code ?? user?.customer_code
        };

        const res = await client.get("products", { params: params });

        // console.log(res);

        return res.data.data.map((item: any) => {
            return { label: `${item.code} - ${item.description} (${parseInt(item.piecesperpackage, 10)} ${item.piecesperpackageuom})`, ...item };
        });
    }, [user]);

    const loadItemsOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getProducts(value).then((options) => callback(options));
        }, 1000);
    }, [getProducts]);

    return (
        <>
            <ComplaintProductDetails
                loadItemsOptions={loadItemsOptions}
                handleItemSearchChange={handleItemSearchChange}
            />

            {/* {
                watchItemCode &&
                <ComplaintSelectOrder flow="product"/>
            } */}

            {
                // watchComplaintOrders?.length > 0 &&
                watchItemCode &&
                <>
                    <ComplaintDescription/>

                    <ComplaintAttachFiles/>

                    <SelectProductLine/>
                </>
            }
        </>
    );
};
