const Switch = ({ checked, setChecked, label, disabled }: any) => {
    return (
        <>
            {/* <label>{label}</label> */}
            <label className={`flex items-center gap-x-2 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                {label}
                <div
                    className={`
                        flex items-center w-[46px] h-[25px] px-[2px] rounded-full ${disabled ? 'bg-gray-200' : checked ? 'bg-secondary' : 'bg-primary-light'}`}
                >
                    <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        disabled={disabled}
                    />
                    <span className={`inline-block w-[21px] h-[21px] rounded-full peer-checked:translate-x-full transition-all ${disabled ? 'bg-disabled' : 'bg-white'}`}></span>
                </div>
            </label>
        </>
    )
}

export default Switch