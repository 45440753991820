import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import { useOrderMutations } from "../api/orders/useOrderMutations";
import { Button } from "../components/Button";
import { CartClientInfo } from "../components/cart/CartClientInfo";
import ExtraServicesSummary from "../components/cart/step_3/ExtraServicesSummary";
import ShippingInfoSummary from "../components/cart/step_3/ShippingInfoSummary";
import { Modal } from "../components/commom/Modal";
import ProductsSummary from "../components/commom/ProductsSummary";
import useOrder from "../api/orders/useOrder";
import { Loader } from "../components/Loader";
import CartCostsSummary from "../components/cart/step_3/CartCostsSummary";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { InfoTooltip } from "../components/commom/InfoTooltip";
import { getCurrencyDecimals } from "../utils/getCurrencyDecimals";
import { convertMiliseconds, getExpiredDays } from "../utils/utils";
import RothoNotesSummary from "../components/cart/step_3/RothoNotesSummary";
import useBlockedOrder from "../api/orders/useBlockedOrder";
import DuplicationModal from "../components/templates/DuplicationModal";
import Table from "../components/Table";

const Order = ({ setTitle }: any) => {
    const { id } = useParams();
    const { user, roles } = useAuthContext();

    // hooks
    const { t } = useTranslation();
    const { unblockOrCancelOrder, duplicateOrder } = useOrderMutations();

    // state
    const [openUnblockModal, setOpenUnblockModal] = useState(false);
    const [unblockReason, setUnblockReason] = useState("");
    const [reasonError, setReasonError] = useState<null | string>(null);
    const [openDuplicateOrder, setOpenDuplicateOrder] = useState<any>(false);

    // react query
    const { order, isLoading, isFetching } = useOrder(id);
    const { blockedOrder } = useBlockedOrder(order?.data?.id, openUnblockModal);

    useEffect(() => {
        setTitle(t("Ordine {{order_number}}", { order_number: order?.data?.semiramis_number ? order?.data?.semiramis_number : order?.data?.id, }));
    }, [order]);

    const totalBlockedAmount =
        blockedOrder?.data?.customer?.unprocessedOrders?.reduce(
            (acc: number, obj: any) => acc + parseFloat(obj.total_net_price),
            0
        );
    const residualCredit =
        order?.data?.customer?.finance?.creditlimit -
        order?.data?.customer?.finance?.unpaid -
        order?.data?.customer?.finance?.undelivered -
        order?.data?.customer?.finance?.deliverednotinvoiced -
        order?.data?.customer?.finance?.invoicednottransferred -
        totalBlockedAmount;

    const numberFormat = order
        ? new Intl.NumberFormat(order?.data?.customer?.language_id, {
            style: "currency",
            currency: order?.data?.customer?.currency,
            minimumFractionDigits: getCurrencyDecimals(order?.data?.customer?.currency),
            maximumFractionDigits: getCurrencyDecimals(order?.data?.customer?.currency),
        })
        : null;

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    const invoicesColumns = [
        {
            Header: t("Numero fattura").toString(),
            accessor: "number",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div>
                        <div className="font-normal">
                            {props?.row.original.number}
                        </div>
                        <div>{t(props?.row.original.type)}</div>
                    </div>
                );
            },
        },
        {
            Header: t("Data scadenza").toString(),
            accessor: "due_date",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return dateFormat.format(
                    new Date(props?.row.original.due_date)
                );
            },
        },
        {
            Header: t("Ritardo").toString(),
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return props.row.original.type === "Invoice" ? getExpiredDays(props.row.original.due_date) > 0 ? convertMiliseconds(getExpiredDays(props.row.original.due_date), "d") : "" : "";
            },
        },
        {
            Header: t("Totale").toString(),
            accessor: "amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.amount);
            },
        },
        {
            Header: t("Pagato").toString(),
            accessor: "paid_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.paid_amount);
            },
        },
        {
            Header: t("Aperto").toString(),
            accessor: "open_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.amount);
            },
        },
    ];

    const handleUnblockReasonChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setUnblockReason(e.target.value);

        // clear state if there's no more error
        if (unblockReason.length > 20) {
            setReasonError(null);
        }
    };

    const handleUnblockReasonBlur = () => {
        if (unblockReason.length <= 0) {
            setReasonError(t("La motivazione è obbligatoria"));
        } else if (unblockReason.length < 20) {
            setReasonError(t("La motivazione deve avere minimo 20 caratteri"));
        } else {
            setReasonError(null);
        }
    };

    const handleUnblockOrder = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();

        if (reasonError) return;

        await toast.promise(
            unblockOrCancelOrder.mutateAsync({
                order_id: order?.data?.id,
                action: "unblock",
                reason: unblockReason,
            }),
            {
                success: t("Ordine sbloccato con successo"),
                error: {
                    render({ data }: any) {
                        if (data?.response?.status === 403) {
                            return t(data?.response?.data?.message);
                        } else {
                            return t("Qualcosa è andato storto");
                        }
                    },
                },
            }
        );

        setOpenUnblockModal(false);
    };

    const handleCancelOrder = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if (reasonError) return;

        try {
            await unblockOrCancelOrder.mutateAsync({
                order_id: order?.data?.id,
                action: "cancel",
                reason: unblockReason,
            });
            toast.success(t("Ordine stornato con successo"));
            setOpenUnblockModal(false);
        } catch (err) {
            toast.error(t("Qualcosa è andato storto"));
        }
    };

    return (
        <div>
            {(isLoading || unblockOrCancelOrder.isLoading || isFetching || duplicateOrder.isLoading) && (
                <Loader/>
            )}
            {order && (
                <>
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        transition={Slide}
                    />

                    {/* DUPLICATE ORDER MODAL  */}
                    <DuplicationModal
                        label={t("Duplica ordine")}
                        modalState={openDuplicateOrder}
                        setModalState={setOpenDuplicateOrder}
                        data={order?.data}
                        type="order"
                    />

                    {/* ORDER UNBLOCKED MESSAGE */}
                    {order?.data?.unblock_reason &&
                        !order?.data?.blocked &&
                        !order?.data?.canceled_at && (
                        <div className="flex items-center gap-x-1 px-6 mb-2 text-xs">
                            <p className="text-sm font-bold">
                                {t("L’ordine è stato sbloccato")}.
                            </p>
                            <InfoTooltip>
                                <div className="space-y-1 p-2">
                                    <div className="space-x-1 flex">
                                        <div>{t("Utente che ha effettuato lo sblocco")} :</div>
                                        <div className="font-normal first-letter:uppercase">{order?.data?.unblocked_canceled_by?.name}</div>
                                    </div>
                                    <div className="space-x-1 flex">
                                        <div>{t("Motivo dello sblocco")}:</div>
                                        <div className="font-normal first-letter:uppercase whitespace-normal">{order?.data?.unblock_reason}</div>
                                    </div>
                                </div>
                            </InfoTooltip>
                        </div>
                    )}

                    <CartClientInfo data={order}/>

                    {/* spedizione consegna e pagamento */}
                    <ShippingInfoSummary data={order} numberFormat={numberFormat} summary={order?.data?.summary}/>

                    <div className="overflow-scroll">
                        <ProductsSummary data={order}/>
                    </div>

                    <ExtraServicesSummary data={order}/>

                    <RothoNotesSummary data={order}/>

                    <CartCostsSummary data={order} summary={order?.data?.summary}/>

                    <div className="text-right space-x-2 mt-4">
                        <Button
                            variant={Button.variant.secondary}
                            onClick={() => setOpenDuplicateOrder(true)}
                        >
                            {t("Duplica ordine")}
                        </Button>
                        {order?.data?.can_be_unblocked && order?.data?.blocked && !order?.data?.canceled_at && (
                            <Button
                                variant={Button.variant.primary}
                                onClick={() => setOpenUnblockModal(true)}
                            >
                                {t("Sblocca ordine")}
                            </Button>
                        )}
                    </div>

                    {!order?.data?.blocked && !order?.data?.canceled_at ? (
                        roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? (
                            <div className="text-xs text-primary-light py-2 px-6">
                                <p>{t("L'ordine è stato confermato - per eventuali modifiche contattare il TS")}</p>
                                <div className="flex gap-x-1">
                                    {
                                        <>
                                            {order?.data?.customer?.technicalSale?.name && (<p>{order?.data?.customer?.technicalSale?.name}{" "}</p>)}
                                            {order?.data?.customer?.technicalSale?.email && (<p>-{" "}{order?.data?.customer?.technicalSale?.email}</p>)}
                                            {order?.data?.customer?.technicalSale?.phone && (<p>-{" "}{order?.data?.customer?.technicalSale?.phone}</p>)}
                                        </>
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className="text-xs text-primary-light py-2 px-6 space-y-1">
                                <p>{t("L'ordine è stato confermato - per eventuali modifiche contattare il CS")}</p>
                                <div className="flex gap-x-1">
                                    {
                                        <>
                                            {order?.data?.customer?.assistant?.name && (<p>{order?.data?.customer?.assistant?.name}{" "}</p>)}
                                            {order?.data?.customer?.assistant?.email && (<p> -{" "}{order?.data?.customer?.assistant?.email}</p>)}
                                            {order?.data?.customer?.assistant?.phone && (<p> -{" "}{order?.data?.customer?.assistant?.phone}</p>)}
                                        </>
                                    }
                                </div>
                            </div>
                        )
                    ) : null}

                    {/* UNBLOCK ORDER MODAL */}
                    {openUnblockModal && (
                        <Modal                            open={openUnblockModal}                            title={t("Sblocca ordine")}                            onClose={setOpenUnblockModal}                        >
                            <div className="space-y-4">
                                <p className="font-normal">
                                    {t("Attenzione: aggiornamento dati in tempo reale, i valori potrebbero differire dalla mail di sblocco")} .
                                </p>
                                {/* FINANCIAL INFO */}
                                <div className="border p-4 space-y-2">
                                    <div className="font-normal">
                                        {t("Ordine E-Service {{order_id}} bloccato per {{block_reason}}", {
                                            order_id: blockedOrder?.data?.id,
                                            block_reason: blockedOrder?.data?.block_reason === "creditlimit" ? t("Fuori fido").toLocaleLowerCase() : t("Insoluto").toLocaleLowerCase(),
                                        }
                                        )}
                                    </div>
                                    <div>
                                        {t("Cliente")}:{" "}
                                        {blockedOrder?.data?.customer?.name} -{" "}
                                        {blockedOrder?.data?.customer?.code}
                                    </div>
                                    <p>
                                        {t("Insoluto")}:{" "}
                                        <span className={`${blockedOrder?.data?.customer?.finance?.unpaidexpired > 0 ? "text-rose-500" : ""}`}>
                                            {numberFormat ? numberFormat?.format(blockedOrder?.data?.customer?.finance?.unpaidexpired) : ""}
                                        </span>
                                    </p>
                                    <p>
                                        {t("Partite aperte")}:{" "}
                                        {numberFormat?.format(blockedOrder?.data?.customer?.finance?.unpaid)}
                                    </p>
                                    <p>
                                        {t("Fido")}:{" "}
                                        {numberFormat?.format(blockedOrder?.data?.customer?.finance?.creditlimit)}
                                    </p>
                                    <p>
                                        {t("Ordine attuale")}:{" "}
                                        {numberFormat?.format(blockedOrder?.data?.total_net_price)}
                                    </p>
                                    <p>
                                        {t("Totale ordini bloccati E-service")}:{" "}
                                        {numberFormat?.format(totalBlockedAmount)}
                                    </p>
                                    <p>
                                        {t("Fido residuo")}:{" "}
                                        <span className={`${residualCredit < 0 ? "text-rose-500" : ""}`}>
                                            {numberFormat?.format(residualCredit)}
                                        </span>
                                    </p>
                                    <p>
                                        {t("Totale fatture ivate ultimi 24 mesi")} :{" "}
                                        <span>
                                            {numberFormat ? numberFormat.format(blockedOrder?.data?.customer?.customerInvoices?.reduce((acc: number, obj: any) => acc + obj.netamount, 0)) : ""}
                                        </span>
                                    </p>
                                    <p>
                                        {t("Rothoblaas Rating")}:{" "}
                                        <span className={blockedOrder?.data?.customer?.invoicing?.internalrating === 3 ? "text-green-500" : blockedOrder?.data?.customer?.invoicing?.internalrating === 2 ? "text-yellow-500" : "text-rose-500"}>
                                            {blockedOrder?.data?.customer?.invoicing?.internalrating === 0 && "No rating"}
                                            {blockedOrder?.data?.customer?.invoicing?.internalrating === 1 && "Red"}
                                            {blockedOrder?.data?.customer?.invoicing?.internalrating === 2 && "Yellow"}
                                            {blockedOrder?.data?.customer?.invoicing?.internalrating === 3 && "Green"}
                                        </span>
                                    </p>
                                    <p>
                                        {t("Finance Rating")}:{" "}
                                        <span className={blockedOrder?.data?.customer?.invoicing?.failurescore === 3 ? "text-green-500" : blockedOrder?.data?.customer?.invoicing?.failurescore === 2 ? "text-yellow-500" : "text-rose-500"}>
                                            {blockedOrder?.data?.customer?.invoicing?.failurescore === 0 && "No rating"}
                                            {blockedOrder?.data?.customer?.invoicing?.failurescore === 1 && "Red"}
                                            {blockedOrder?.data?.customer?.invoicing?.failurescore === 2 && "Yellow"}
                                            {blockedOrder?.data?.customer?.invoicing?.failurescore === 3 && "Green"}
                                        </span>
                                    </p>
                                    <p>
                                        {t("TS")}:{" "}
                                        {blockedOrder?.data?.customer?.technicalSale?.name}
                                    </p>
                                    <p>
                                        {t("ASM")}:{" "}
                                        {blockedOrder?.data?.customer?.areaManager?.name}
                                    </p>

                                    {blockedOrder && (
                                        <Table
                                            variants={Table.variants.expandable}
                                            label="Estratto conto"
                                            data={blockedOrder?.data?.customer?.open_nav_payments}
                                            columns={invoicesColumns}
                                            emptyStateMessage="Nessuna fattura aperta"
                                        />
                                    )}
                                </div>

                                {/* REASON */}
                                <div>
                                    <label>
                                        {t("Inserisci la motivazione dello sblocco/storno:")}
                                    </label>
                                    <textarea
                                        className={`${reasonError && "border-rose-500 outline-rose-500"}`}
                                        value={unblockReason}
                                        onChange={(e) => {
                                            handleUnblockReasonChange(e);
                                        }}
                                        onBlur={handleUnblockReasonBlur}
                                    />
                                    {reasonError && (
                                        <p className="text-rose-500">
                                            {reasonError}
                                        </p>
                                    )}
                                </div>

                                <div className="text-right space-x-2">
                                    <Button
                                        variant={Button.variant.text}
                                        onClick={(e) => handleCancelOrder(e)}
                                    >
                                        {t("Nega")}
                                    </Button>
                                    <Button
                                        variant={Button.variant.primary}
                                        onClick={(e) => handleUnblockOrder(e)}
                                    >
                                        {t("Autorizza")}
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    )}
                </>
            )}
        </div>
    );
};

export default Order;
