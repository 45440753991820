import { useMutation } from "@tanstack/react-query";
import client from "../client";

type MastefileType = {
    customer_code: string;
}

export default function useMastefileMutation() {
    const createMasterfile = useMutation(async (params: MastefileType) => {
        return await client.post("/mastefile", params);
    }, {
        onError: (error) => {
            console.error("Error creating masterfile:", error);
        }
    });
    return { createMasterfile };
}
