import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useTable, useSortBy, usePagination, Column, useExpanded } from "react-table";
import { Status, StatusVariant } from "../../Status";
import { Link } from "react-router-dom";
import { EservicesStatus } from "../../../constants/EservicesStatus";
import { ComplaintsSearchParams } from "./ComplaintsSearchBar";
import { SortableHeader } from "../../commom/SortableHeader";

export const ComplaintsTable = ({ renderRowSubComponent, items, setParams }: any) => {
    const { t } = useTranslation();

    // table data
    const data = useMemo(
        () => {
            return items;
        },
        [items]
    );

    const columns: Array<Column<any>> = useMemo(
        () => [
            {
                id: "expander",
                Header: "",
                disableSortBy: true,
                className: "border-b p-4",
                Cell: ({ row }: any) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row

                    <span
                        {...row.getToggleRowExpandedProps({
                            style: {
                                // We can even use the row.depth property
                                // and paddingLeft to indicate the depth
                                // of the row
                                paddingLeft: `${row.depth * 2}rem`,
                            },
                            tabIndex: 0
                        })}
                    >
                        {row.isExpanded ? <HiChevronUp className="text-xl" /> : <HiChevronDown className="text-xl" />}
                    </span>
            },
            {
                id: "complaint_number",
                Header: t("Numero reclamo").toString(),
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props: any) => {
                    return <Link className="hover:underline" to={"complaint/" + props?.row?.original?.id}>{props?.row?.original?.number}</Link>;
                }
            },
            {
                id: "complaint_type",
                Header: t("Tipo reclamo").toString(),
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props: any) => {
                    return props?.row?.original?.complaint_type.description;
                }
            },
            {
                id: "creator",
                Header: t("Utente").toString(),
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props: any) => {
                    let creator;
                    if (props.row.original.creatorEmployee) {
                        creator = props.row.original.creatorEmployee?.fullname;
                    } else if (props.row.original.creatorSales) {
                        creator = props.row.original.creatorSales?.fullname;
                    } else {
                        creator = props.row.original.creator_code;
                    }

                    return creator ?? "-";
                }
            },
            {
                id: "client_code",
                Header: t("Codice cliente").toString(),
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props: any) => {
                    return props?.row?.original?.customer?.code;
                }
            },
            {
                id: "client_name",
                Header: t("Nome cliente").toString(),
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props: any) => {
                    return props?.row?.original?.customer?.name;
                }
            },
            {
                id: "error_type",
                Header: t("Tipo di errore").toString(),
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props: any) => {
                    return props?.row?.original?.complaint_classification_one?.description;
                }
            },
            {
                id: "creationDate",
                Header: (props) => {
                    return <SortableHeader props={props} title={t("Data").toString()} />;
                },
                accessor: "creationDate",
                className: "border-b p-4 text-left",
                Cell: (props: any) => {
                    return props?.row?.original?.date;
                }
            },
            {
                id: "technical_salesman",
                Header: t("Technical Salesman").toString(),
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props: any) => {
                    return props?.row?.original?.customer?.ts_fullname;
                }
            },
            {
                id: "status",
                Header: t("Stato").toString(),
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props: any) => {
                    const complaintStatus = props?.row?.original?.status;

                    const status = EservicesStatus.complaints.find((status) => status.status === complaintStatus);
                    const statusVariant = status?.variant as keyof typeof StatusVariant;

                    if (!statusVariant) return "";

                    return <Status variant={Status.variant[statusVariant]}>{t(status?.description ?? "")}</Status>;
                }
            }
        ],
        [t]
    );

    const defaultColumn = useMemo(
        () => ({
            width: "auto",
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        page,
        state: { sortBy }
    } = useTable({
        columns,
        data,
        defaultColumn,
        manualSortBy: true,
        disableSortRemove: true,
        initialState: { pageSize: 10 },
        // dateFormat
    }, useSortBy, useExpanded, usePagination);

    useEffect(() => {
        if (["creationDate"].includes(sortBy[0]?.id))
            setParams((prevParams: ComplaintsSearchParams) => ({ ...prevParams, sort: sortBy[0].desc ? sortBy[0].id : `-${sortBy[0].id}` }));
    }, [sortBy]);

    return (
        <>
            <table {...getTableProps()} className="border w-full text-xs font-light overflow-x-scroll block xl:table">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    <span className='flex items-center'>
                                        <span className="flex-1">
                                            {column.render("Header")}
                                        </span>
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            // Use a React.Fragment here so the table markup is still valid
                            <React.Fragment key={row.getRowProps().key}>
                                <tr>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps({ className: (cell.column as any).className, style: { width: cell.column.width, maxWidth: cell.column.maxWidth } })}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {/*
                                    If the row is in an expanded state, render a row with a
                                    column that fills the entire length of the table.
                                */}
                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length} className="border">
                                            {/*
                                                Inside it, call our renderRowSubComponent function. In reality,
                                                you could pass whatever you want as props to
                                                a component like this, including the entire
                                                table instance. But for this example, we'll just
                                                pass the row
                                            */}
                                            {renderRowSubComponent({ row, rowProps: row.getRowProps(), visibleColumns })}
                                        </td>
                                    </tr>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};
